<template>
  <div class="navbar cel" v-if="options.length > 0">
    <a v-for="option in options" :key="option.to" :href="option.to">{{option.label}}</a>
  </div>
</template>

<script>
export default {
  name: 'ControlBar',
  props: {
  },
  computed:{    
    options(){
      //this.updateControlBar++;
      let options = [];

      // User, login, and register
      /*
      if(this.$store.state.isLoggedIn){
        options.push({ label: `${this.$store.state.user.userName} (${this.$store.state.user.userLevel})`, emit: 'profile' });
      }else{
        options.push({ label: `Login`, emit: 'login' });
        options.push({ label: `Register`, emit: 'register' });
      }*/

      // Screen specific options
      switch(this.$store.state.screenType){

        case 'boardList':
          break;

        case 'board':
          options.push({ label: 'Board List', to: '/b' });
          if(this.$store.state.isLoggedIn){
            options.push({ label: 'Create New Topic', to: `${this.$store.state.dynamicUrl}/post` });
            //options.push({ label: 'Add to Favorites', emit: 'fav' });
          }
          break;

        case 'login':
        case 'register':
        case 'home':
          options.push({ label: 'Board List', to: '/b' });
          break;
          
        case 'postReply':      
          options.push({ label: 'Return to Topic List', to: `/b${this.$store.state.boardId}` });    
          options.push({ label: 'Return to Message List', to: `/b${this.$store.state.boardId}/t${this.$store.state.topicId}` });
          break;
          
        case 'postTopic':          
          options.push({ label: 'Return to Topic List', to: `/b${this.$store.state.boardId}` });
          break;

        case 'profile':
          options.push({ label: 'Board List', to: '/b' });
          if(this.$store.state.boardId)
            options.push({ label: 'Return to Topic List', to: `/b${this.$store.state.boardId}` });    
          if(this.$store.state.topicId)
            options.push({ label: 'Return to Message List', to: `/b${this.$store.state.boardId}/t${this.$store.state.topicId}` });
          break;

        case 'message':
          options.push({ label: 'Board List', to: '/b' });
          options.push({ label: 'Return To Topic List',to: `/b${this.$store.state.boardId}` });
          options.push({ label: 'Return To Message List', to: `/b${this.$store.state.boardId}/t${this.$store.state.topicId}` });
          break;

        case 'topic':
          options.push({ label: 'Board List', to: '/b' });
          options.push({ label: 'Topic List',to: `/b${this.$store.state.boardId}`});
          if(this.$store.state.isLoggedIn){
            options.push({ label: 'Post New Message',  to: `/b${this.$store.state.boardId}/t${this.$store.state.topicId}/post` });
            //options.push({ label: 'Track Topic', to: `/b${this.$store.state.boardId}/t${this.$store.state.topicId}/track` });
          }
          break;
      }   

      return options;
    }
  },
  methods: {
    optionSelected(option){
      this.$router.push(option.to)
    }
  }
}
</script>

