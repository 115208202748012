<template>
  <div class="w-full flex flex-col gap-y-0.5" >
    <Message v-if="messageInfo" :message="messageInfo" :isMessageDetail="true" />
  </div>
</template>
<script>

import { ref } from 'vue';

import Message from '@/components/Message.vue'

export default {
  name: 'MessageDetail',
  components: {
    Message
  },
  props: {
    boardId: Number,
    messageId: Number,
    topicId: Number
  },
  data(){
    return {
      messageInfo: []
    }
  },
  async mounted(){
    this.initialize()
    //this.message =  await this.getMessage();
  },
  methods:{
    initialize(){      
      // Set params from route
      if (this.$route.params.messageInfo) {
        this.messageInfo = this.$route.params.messageInfo;
      }

      // Set global store information and screen type
      this.$store.state.screenTitle = 'Message Detail'
      document.title = this.$store.state.screenTitle
      this.$store.state.screenType = 'message'


      // Check if user viewing own message
      if(this.$store.state.user.userId == this.messageInfo.userId){
        this.isSelf = true;
        this.signature = this.userInfo.signature;
      }
    }
  },
  setup(){    
    return {
    }
  }
}
</script>
