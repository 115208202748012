<template>
  <div class="form">
    <!-- Header -->
    <div class="form-header cel">{{title}}</div>
    <div v-if="error" class="notice cel">{{error}}</div>
    <!-- Fields -->
    <div v-for="field in fields" :key="field.id" class="form-field cel">
      <label :for="field.id">{{field.label}}</label>
      <input :type="field.type ? field.type : 'text'" :id="field.id" v-model="inputValues[field.id]" />
    </div>
    <!-- Buttons -->
    <div class="form-buttons cel">
      <div @click="this.$emit('submit', inputValues)" class="button">{{submitText}}</div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'Form',
  props: {
    error: String,
    fields: Object,
    input: Object,
    submitText: {
      type: String,
      default: 'Submit'
    },
    title: String

  },
  methods: {
  },
  mounted(){
    
    // Create input refs
    let inputs = {};
    for(var ii = 0; ii < this.$props.fields.length; ii++){
      let field = this.$props.fields[ii]
      inputs[field.id] = new ref('')
    }
    this.inputValues = inputs;

  },
  setup(){
    const inputValues = ref({})
    return {
      inputValues
    }
  }
}
</script>
