import { createRouter, createWebHistory } from 'vue-router'

import BoardList from '../views/BoardList.vue'
import Board from '../views/Board.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import MessageDetail from '../views/MessageDetail.vue'
import Post from '../views/Post.vue'
import Register from '../views/Register.vue'
import Topic from '../views/Topic.vue'
import User from '../views/User.vue'
import store from '../store'; // Adjust the path according to your project structure




function authCheck() {
  console.log('authCheck');
  return new Promise((resolve, reject) => {
    let url = `https://db.b8.wtf/api/users/auth.php`;
    let request = {
      method: 'GET',
      credentials: 'include'
    };

    fetch(url, request)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          /*
          store.commit('SET_AUTH_STATUS', {
            isLoggedIn: true,
            user: data.settings,
            messagesPerPage: data.settings.messagesPerPage,
            topicsPerPage: data.settings.topicsPerPage,
            stickyHeader: data.settings.stickyHeader,
            showSidebar: data.settings.showSidebar,
            unreadMessagesCount: data.settings.unreadMessagesCount
          });*/
          console.log('')
          
          store.state.isLoggedIn = true
          store.state.user = data.settings
          store.state.messagesPerPage = data.settings.messagesPerPage
          store.state.topicsPerPage = data.settings.topicsPerPage
          store.state.stickyHeader = data.settings.stickyHeader
          store.state.showSidebar = data.settings.showSidebar  
          store.state.unreadMessagesCount = data.settings.unreadMessagesCount  
          
          store.state.loaded = true;
          resolve();
        } else {
          store.commit('SET_AUTH_STATUS', {
            isLoggedIn: false,
            user: null
          });
          store.state.loaded = true;
          resolve();
          //reject('Unauthorized');
        }
      })
      .catch(error => {/*
        store.commit('SET_AUTH_STATUS', {
          isLoggedIn: false,
          user: null
        });*/
        
        store.state.isLoggedIn = true
        store.state.user = {}
        store.state.messagesPerPage = 25
        store.state.topicsPerPage = 50
        store.state.stickyHeader = true
        store.state.showSidebar = true  
        store.state.unreadMessagesCount = 0  

        store.state.loaded = true;
        reject(error);
      });
  });
}

// Fetch user, board, topic, message data required for route
function dynamicFetch(to, from, next){
  console.log('dynamicFetch: ', to);
  let host = 'https://db.b8.wtf/api/';

  let fetches = [];
  let fetchTypes = [];

  // Determine which ID to use in fetch urls
  let boardIdUse = to.params.boardId ? to.params.boardId : to.params.boardIdLite
  let topicIdUse = to.params.topicId ? to.params.topicId : to.params.topicIdLite

  // Check the route to determine what to load and set relevant state
  // Load board info
  if(to.params.boardId){
    fetches.push(fetch(`${host}topics/topics.php?boardId=${to.params.boardId}`).then(r => r.json()))
    fetchTypes.push('topics')

    fetches.push(fetch(`${host}boards/board.php?boardId=${to.params.boardId}`).then(r => r.json()))
    fetchTypes.push('boardInfo')
  }
  // Check for just setting board ID without fetching full info
  // Still fetch board name
  else if(to.params.boardIdLite){    
    fetches.push(fetch(`${host}boards/board.php?boardId=${to.params.boardIdLite}`).then(r => r.json()))
    fetchTypes.push('boardInfo')
  }

  // Load user info
  let routeSplit = to.fullPath.split('/');
  let userSelf = routeSplit.indexOf('u') != -1;
  console.log('userSelf: ', userSelf);
  console.log('store.state.user: ', store.state.user);
  console.log('store.state.messagesPerPage: ', store.state.messagesPerPage);
  if(to.params.userName || userSelf){    
    let uname = userSelf ? store.state.user.userName :to.params.userName       
    let postDataUser = {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        credentials: 'include'
      }
    fetches.push(fetch(`${host}users/user.php?userName=${uname}`, postDataUser).then(r => r.json()));
    fetchTypes.push('userInfo')
  }

  // Load topic info
  if(to.params.topicId){  

    let postData = {
    method: 'GET',
    headers: {'Content-Type': 'application/json' },
    credentials: 'include'
  }

    fetches.push(fetch(`${host}topics/topic.php?boardId=${boardIdUse}&topicId=${topicIdUse}&perPage=${store.state.messagesPerPage}&page=${to.params.page ? to.params.page : 1}`, postData).then(r => r.json()));
    fetchTypes.push('topicInfo')
  }
  // Check for just setting topic ID without fetching full info
  // Still fetch topic title
  else if(to.params.topicIdLite){
    let postData = {
      method: 'GET',
      headers: {'Content-Type': 'application/json' },
      credentials: 'include'
    }
    fetches.push(fetch(`${host}topics/topic.php?boardId=${boardIdUse}&topicId=${topicIdUse}&isLite=1`, postData).then(r => r.json()));
    fetchTypes.push('topicInfo')
  }

  // Load message detail info
  if(to.params.messageId){
    let postData = {
      method: 'GET',
      headers: {'Content-Type': 'application/json' },
      credentials: 'include'
    }
    fetches.push(fetch(`${host}topics/message.php?boardId=${boardIdUse}&topicId=${topicIdUse}&messageId=${to.params.messageId}`, postData).then(r => r.json()))
    fetchTypes.push('messageInfo')
  }

  // Use Promise.all to handle all fetches
  Promise.all(fetches)
    .then(data => {
      console.log('data: ', data);
      
      // Clear screen state values
      let boardId = null,
      boardTitle = null,
      screenTitle = null,
      topicId = null,
      topicTitle = null
      
      // Go through all fetched data setting param 
      // and setting relevant store state
      for(var ii = 0; ii < fetchTypes.length; ii++){
        console.log('fetchType: ', fetchTypes[ii], data[ii])
        switch(fetchTypes[ii]){

          case 'boardInfo':
            boardId = data[ii].boardId
            boardTitle = data[ii].title
            break;
            
          case 'topicInfo':
            topicId = data[ii].topicId
            topicTitle = data[ii].topicTitle
            break;
            
        }
        to.params[fetchTypes[ii]] = data[ii];
      }

      // Update stored values
      store.state.boardId = boardId
      store.state.boardTitle = boardTitle
      store.state.screenTitle = screenTitle
      store.state.topicId = topicId
      store.state.topicTitle = topicTitle
      
      next(); // Proceed to the route
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      next(false); // Stop the routing or redirect if needed
    });
}

// /b - Fetch info for board list
function fetchBoards(to, from, next) {
  Promise.all([
    fetch(`${host}boards/categories.php`),
    fetch(`${host}boards/boards.php`),
    fetch(`${host}topics/topics.php?recent=1`)
  ])
  .then(responses => {
    // Check if all responses are ok
    if (!responses.every(response => response.ok)) {
      throw new Error('One or more network responses were not ok');
    }
    // Parse all responses as JSON
    return Promise.all(responses.map(response => response.json()));
  })
  .then(data => {
    // Assuming data[0] is from boardCategories and data[1] is from boards
    to.params.boardCategories = data[0];
    to.params.boards = data[1];
    to.params.recentTopics = data[2];
    next();
  })
  .catch(error => {
    console.error('Error fetching data:', error);
    next(false); // Stop the routing or redirect if needed
  });
}


let host = 'https://db.b8.wtf/api/';
const routes = [// General Access
{ path: '/', name: 'home', component: Home },
{ path: '/login', name: 'login', component: Login },
{ path: '/logout', name: 'logout', component: Logout },
{ path: '/register', name: 'register', component: Register },

// Board Listings
{ path: '/b', name: 'boards', component: BoardList, beforeEnter: fetchBoards },

// Board Details
{ path: '/b:boardId', name: 'board', component: Board, props: true, beforeEnter: dynamicFetch },
{ path: '/b:boardId/post', name: 'post_topic', component: Post, props: true, beforeEnter: dynamicFetch  },
{ path: '/b:boardId/t:topicId/post', name: 'post_message', component: Post, props: true, beforeEnter: dynamicFetch  },

// Topic Details
{ path: '/b:boardIdLite/t:topicId', name: 'topic', component: Topic, props: true, beforeEnter: dynamicFetch },
{ path: '/b:boardIdLite/t:topicId/p:page', name: 'topic_page', component: Topic, props: true, beforeEnter: dynamicFetch },

// Message Details
{ path: '/b:boardIdLite/t:topicIdLite/m:messageId', name: 'messageDetail', component: MessageDetail, props: true, beforeEnter: dynamicFetch },

// User Profiles
{ path: '/u', name: 'user_self', component: User, beforeEnter: dynamicFetch },
{ path: '/b/u', name: 'user_selfBoards', component: User, beforeEnter: dynamicFetch },
{ path: '/u:userName', name: 'user', component: User, props: true, beforeEnter: dynamicFetch },
{ path: '/b/u:userName', name: 'user_boards', component: User, props: true, beforeEnter: dynamicFetch },
{ path: '/b:boardIdLite/t:topicIdLite/u', name: 'user_selfTopic', component: User, props: true, beforeEnter: dynamicFetch },
{ path: '/b:boardIdLite/t:topicIdLite/u:userName', name: 'user_topic', component: User, props: true, beforeEnter: dynamicFetch },
{ path: '/b:boardIdLite/u', name: 'user_selfBoard', component: User, props: true, beforeEnter: dynamicFetch },
{ path: '/b:boardIdLite/u:userName', name: 'user_board', component: User, props: true, beforeEnter: dynamicFetch },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


// Global navigation guard to ensure auth check completes
router.beforeEach((to, from, next) => {
  let sameComp = (to.name === from.name && to.fullPath !== from.fullPath);

  if (!store.state.loaded) {
    // If the auth check has not completed, trigger it
    authCheck().then(() => {

      if(sameComp) dynamicFetch(to, from, next);
      else next();

    }).catch(() => {

      if(sameComp) dynamicFetch(to, from, next);
      else next();

    });
  } else {

    if(sameComp) dynamicFetch(to, from, next);
    else next();

  }
});

export default router
