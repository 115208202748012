<template>
  <!--Post Form-->
  <div class="post">
    <div class="post-header cel">{{!topicId ? 'Create New Topic' : 'Post Reply'}}</div>
    <div class="post-error error cel" v-if="error">{{error}}</div>
    <div v-if="!topicId" class="post-title">
      <label class="post-title-label cel" for="topicTitle">Topic Title</label>
      <div class="post-title-input-container cel">
        <input v-if="!topicId" id="topicTitle" v-model="topicTitle" />
      </div>
    </div>
    <div v-if="messagePreview" class="post-preview-header cel">Preview</div>
    <Message v-if="messagePreview" :showMessageDetailLink="false" :isUsernameLink="false" :showSignature="false"
      :message="{ message: messagePreview }" />
    <label class="post-message-label cel" for="message">Message</label>
    <div class="post-toolbar cel">
      <div><button @click="addTag('b')" @mouseover="toolDesc = '[b]Bold[/b]'" @mouseout="toolDesc = ''">Bold</button></div>
      <div><button @click="addTag('i')" @mouseover="toolDesc = '[i]Italic[/i]'" @mouseout="toolDesc = ''">Italic</button></div>
      <div><button @click="addTag('code')" @mouseover="toolDesc = '[code]Code[/code]'" @mouseout="toolDesc = ''">Code</button></div>
      <div><button @click="addTag('spoiler')" @mouseover="toolDesc = '[spoiler]Spoiler[/spoiler]'" @mouseout="toolDesc = ''">Spoiler</button></div>
      <div><button @click="addImage" @mouseover="toolDesc = '[img]Image URL[/img]'" @mouseout="toolDesc = ''">Image</button></div>
      <div><button @click="addColor" @mouseover="toolDesc = '[color=red]Color[/color]'" @mouseout="toolDesc = ''">Color</button></div>
      <div><button @click="addLink" @mouseover="toolDesc = '[url=https://]Link[/url]'" @mouseout="toolDesc = ''">Link</button></div>
      <div><button @click="addTag('yt')" @mouseover="toolDesc = '[yt]https://youtube-video[/yt]'" @mouseout="toolDesc = ''">YouTube</button></div>
      <div class="post-toolbar-tooltip" v-html="toolDesc" />
    </div>
    <div class="post-message-container cel">
      <textarea v-model="messageRaw" id="message" ref="textarea"  @keydown="handleShortcut"/>
    </div>    
    <!-- Buttons -->
    <div class="post-buttons cel">
      <div @click="messagePreview = messageRaw" class="post-button-preview">Preview</div>
      <div @click="post()" class="post-button">Post Message</div>
    </div>    
  </div>
</template>

<script>

import { ref } from 'vue';

import ControlBar from '@/components/ControlBar.vue'
import Message from '@/components/Message.vue'
import PageBar from '@/components/PageBar.vue'

export default {
  name: 'Post',
  components: {
    ControlBar,
    Message,
    PageBar
  },
  props: {
    boardId: Number,
    topicId: Number
  },
  async mounted(){
    if(this.$props.topicId)
      this.$store.state.screenType = 'postReply';
    else
      this.$store.state.screenType = 'postTopic';

    this.$store.state.dynamicUrl = `/b${this.$store.state.boardId}/t${this.$store.state.topicId}`;


    await this.updatePostToInfo();
  },
  computed:{    
    
  },
  methods:{     
    handleShortcut(event) {
      if (event.ctrlKey || event.metaKey) {
        switch (event.key.toLowerCase()) {
          case 'b':
            event.preventDefault();
            this.addTag('b');
            break;
          case 'i':
            event.preventDefault();
            this.addTag('i');
            break;
          case 's':
            event.preventDefault();
            this.addTag('spoiler');
            break;
          case 'u':
            event.preventDefault();
            this.addTag('i');
            break;
          default:
            break;
        }
      }
    },
    addTag(tag, defaultValue) {
  const startPos = this.$refs.textarea.selectionStart;
  const endPos = this.$refs.textarea.selectionEnd;
  const selectedText = this.messageRaw.substring(startPos, endPos);
  let tagText = `[${tag}]${selectedText}[/${tag}]`;
  let insertionPoint = startPos + tag.length + 2; // Default for simple tags

  if (defaultValue) {
    tagText = `[${tag}=${defaultValue}]${selectedText}[/${tag}]`;
    insertionPoint = startPos + tag.length + defaultValue.length + 3; // Adjust for tags with attributes
  }

  if (selectedText.length === 0) {
    // Place cursor inside the tag if no text is highlighted
    this.insertTextAtCursor(tagText, insertionPoint);
  } else {
    // Highlight the default value or the entire selected text
    this.insertTextAtCursor(tagText, insertionPoint, insertionPoint + selectedText.length);
  }
},
addImage() {
  const selectedText = this.getSelectedText();
  let imageUrl = 'https://';
  let imgTag = `[img]${imageUrl}[/img]`;
  let urlStartPos = this.$refs.textarea.selectionStart + 5; // Start position of the URL within the tag

  if (selectedText) {
    imageUrl = selectedText;
    imgTag = `[img]${imageUrl}[/img]`;
  }

  // Highlight the URL part
  this.insertTextAtCursor(imgTag, urlStartPos, urlStartPos + imageUrl.length);
},
    addColor() {
      this.addTag('color', 'red'); // Default to red, users can modify as needed
    },
  addLink() {
    const selectedText = this.getSelectedText();
    if (!selectedText) {
      this.insertTextAtCursor('[link=https://]Link Title[/link]', this.$refs.textarea.selectionStart + 6, 'https://');
    } else if (this.isValidUrl(selectedText)) {
      this.insertTextAtCursor(`[link=${selectedText}]${selectedText}[/link]`, this.$refs.textarea.selectionStart + selectedText.length + 7);
    } else {
      this.insertTextAtCursor(`[link=https://]${selectedText}[/link]`, this.$refs.textarea.selectionStart + 6, 'https://');
    }
  },
  getSelectedText() {
    const startPos = this.$refs.textarea.selectionStart;
    const endPos = this.$refs.textarea.selectionEnd;
    return this.messageRaw.substring(startPos, endPos);
  },insertTextAtCursor(text, selectionStart, selectionEnd) {
  const startPos = this.$refs.textarea.selectionStart;
  const endPos = this.$refs.textarea.selectionEnd;
  this.messageRaw = this.messageRaw.slice(0, startPos) + text + this.messageRaw.slice(endPos);
  this.$nextTick(() => {
    this.$refs.textarea.focus();
    if (selectionEnd !== undefined) {
      // If selectionEnd is provided, select the range
      this.$refs.textarea.selectionStart = selectionStart;
      this.$refs.textarea.selectionEnd = selectionEnd;
    } else {
      // If no selectionEnd, place the cursor at selectionStart (assuming it's a single cursor position)
      this.$refs.textarea.selectionStart = this.$refs.textarea.selectionEnd = selectionStart;
    }
  });
},
    isValidColor(color) {
      // Simple validation for hex codes and basic color names
      const s = new Option().style;
      s.color = color;
      // Return true if the browser accepts it as a valid color
      return s.color !== '';
    },
  isValidUrl(url) {
    try {
      const parsedUrl = new URL(url);
      return ['http:', 'https:'].includes(parsedUrl.protocol);
    } catch (error) {
      return false;
    }
  },
    // Get info about the board or topic being posted to
    async updatePostToInfo(){
      this.postToInfo = await this.updatePostToInfoRequest();
      console.log('postToInfo: ', this.postToInfo)
      // Set screen title to board or topic title
      if(this.postToInfo.topicTitle){
        this.$store.state.screenTitle = this.postToInfo.topicTitle;
      }else if(this.postToInfo.boardTitle){
        this.$store.state.screenTitle = this.postToInfo.boardTitle;
      }
    },
    updatePostToInfoRequest(){
      console.log('updatePostToInfoRequest');
        return new Promise(resolve => {
            fetch(`${this.host}users/post.php?boardId=${this.$props.boardId}&topicId=${this.$props.topicId}&mode=getInfo`, {
                method: 'GET', 
                headers: {
                  'Content-Type': 'application/json'
                },
                credentials: 'include'
                })
                .then(res => res.json())
                .then(data => { resolve(data); })
                .catch(error => { resolve(error); })  
        });
    },
    async post(){
      await this.postRequest();
    },
    postRequest(){
      let url = `${this.host}users/post.php`
      let data = 
      {
        message: this.messageRaw,
        topicTitle: this.topicTitle,
        boardId: this.$props.boardId,
        topicId: this.$props.topicId,
        //token: localStorage.getItem('token'),
        mode: 'post'
      }
      let request = {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
      }

      // Send the request to the server
      fetch(url, request)
        .then(response => response.json()) // Parsing the JSON response
        .then(data => {
            if (data.success) {
              // Go to topic after posted
              this.$router.push(`/b${data.boardId}/t${data.topicId}`)
            } else {
              this.error = data.message
            }
        })
        .catch(error => {
              this.error = error
        });
    }
  },
  setup(){

    const board = ref({})
    const topic = ref({})
    const topicTitle = ref('');
    const toolDesc = ref('')
    const messagePreview = ref('')
    const messageRaw = ref('');
    const showPreview = ref(false);
    const postToInfo = ref({});
    const error = ref('')

  return {
      board,
      error,
      messagePreview,
      messageRaw,
      postToInfo,
      showPreview,
      toolDesc,
      topicTitle
    }
  }
}
</script>
