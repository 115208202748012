<template>
  <div class="w-full flex flex-col gap-y-0.5">
    <PageBar :pages="pages" :page="page" @selectPage="selectPage" />
    <Message v-for="(message, i) in topicInfo.messages" :key="message.messageId" :message="message" :boardId="$store.state.boardId" :topicId="message.topicId" 
      :number="((page - 1) * this.$store.state.messagesPerPage) + (i+1)" :showMessageDetailLink="true" :showSignature="true" :showMessageNumber="true" />
    <PageBar :pages="pages" :page="page" :isNumbered="true" @selectPage="selectPage" />
  </div>
</template>

<script>

import { ref } from 'vue';

import ControlBar from '@/components/ControlBar.vue'
import Message from '@/components/Message.vue'
import PageBar from '@/components/PageBar.vue'

export default {
  name: 'GenMessage',
  components: {
    ControlBar,
    Message,
    PageBar
  },
  props: {
    page: Number
  },
  data(){
    return {
      topicInfo: []
    }
  },
  async mounted(){
    if(this.$props.page)
      this.page = this.$props.page;
    this.initialize();
  },
  methods:{
    initialize(){
      
      // Set params from route
      if (this.$route.params.topicInfo) {
        this.topicInfo = this.$route.params.topicInfo;
      }
      
      this.$store.state.dynamicUrl = `/b${this.$store.state.boardId}/t${this.$store.state.topicId}`;

      // Set screen title and screen type
      this.$store.state.screenTitle = this.$store.state.topicTitle
      document.title = this.$store.state.screenTitle
      this.$store.state.screenType = 'topic'

      this.pages = Math.ceil(this.topicInfo.messageCount / this.$store.state.messagesPerPage)      
    },
    selectPage(page){
      this.$router.push(`/b${this.$store.state.boardId}/t${this.$store.state.topicId}/p${page}`);
    }
  },
  setup(){

    let page = ref(1);
    let pages = ref(1);
    
    return {
      page,
      pages
    }
  }
}
</script>
