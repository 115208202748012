<template>
  <div class="main">
    <SideBar v-if="this.$store.state.showSidebar" />  
    <div class="content">
      <!-- Path / User Bar -->
      <Path />
      <div class="screen-title">{{$store.state.screenTitle}}</div>
      <ControlBar />
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
import ControlBar from '@/components/ControlBar.vue'
import Path from '@/components/Path.vue'
import SideBar from '@/components/SideBar.vue'

import { ref } from 'vue'

export default {
  components: {
    ControlBar,
    Path,
    SideBar
  },
  async mounted(){
  },
  methods: {
  },
  setup(){    
    return{
    }

  }
}
</script>


