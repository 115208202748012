<template>
  <div class="pagebar">
    <!-- Numbered Page Nav -->
    <div v-if="isNumbered" class="pagebar-numbered cel">
        <div class="pagebar-title">Pages:</div>
        <a v-for="i in pages" :key="i" :href="`${this.$store.state.dynamicUrl}/p${i}`"
          :class="i == page ? 'page-active' : ''">{{i}}</a>
    </div>
    <!-- Top Page Nav -->
    <div v-else class="pagebar-top cel">
      <a :href="`${this.$store.state.dynamicUrl}/p${1}`" v-if="page > 1" >First Page</a>
      <a :href="`${this.$store.state.dynamicUrl}/p${page-1}`" v-if="page > 2" >Prev Page</a>
      <div>Page {{page}} of {{pages}}</div>
      <a :href="`${this.$store.state.dynamicUrl}/p${page+1}`" v-if="page < pages -1">Next Page</a>
      <a :href="`${this.$store.state.dynamicUrl}/p${pages}`" v-if="page < pages">Last Page</a>    
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageBar',
  props: {
    isNumbered: Boolean,
    pages: Number,
    page: Number
  },
  methods: {
  }
}
</script>
