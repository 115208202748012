<template>
    <div class="message">
      <div v-if="message.userName || message.created" class="message-header cel">
        <span v-if="message.userName" class="from-container">
          <span class="from-label">From</span>
          <span class="messager-username" v-if="isUsernameLink">{{message.userName}}</span>
          <a class="messager-username" v-else :href="`/b${boardId}/t${topicId}/u${message.userName}`">{{message.userName}}</a>
        </span>
        <span v-if="message.created" class="message-posted">Posted {{message.created}}</span>
        <a v-if="showMessageDetailLink" class="message-link" :href="`/b${boardId}/t${topicId}/m${message.messageId}`">Message Detail</a>
        <span class="message-number" v-if="showMessageNumber" >#{{number}}</span>
      </div>
      <div class="message-body cel" v-html="`${messageParsed}${showSignature && message.signature ? '\n---\n'+message.signature : ''}`" />
    </div>
</template>

<script>
export default {
  name: 'Path',
  props: {
    boardId: Number,
    topicId: Number,
    message: Object,
    number: Number,
    isUsernameLink: Boolean,
    showMessageDetailLink: Boolean,
    showMessageNumber: Boolean,
    showSignature: Boolean
  },
  mounted(){    
    // Listener for toggling embedded youtube video size
    this.$el.addEventListener('click', (event) => {
      if (event.target.classList.contains('expand-button')) {
        this.toggleVideoSize(event);
      }
    });
  },
  methods: {
    escapeHTML(text) {
      let div = document.createElement('div');
      div.textContent = text;
      return div.innerHTML;
    },
    formatImage(imgUrl, size, linkUrl = imgUrl) {
      const imageSizeStyle = size === 'full' ? 'max-width: 100%; height: auto;' : `max-width: ${size || 200}px; max-height: ${size || 200}px;`;
      const imageHtml = `<img src="${imgUrl}" style="${imageSizeStyle}">`;

      return `<a href="${linkUrl}" target="_blank" rel="noopener noreferrer">${imageHtml}</a>`;
    },
    formatQueryString(queryString) {
      // Ensure the query string starts with '?' and replace '&' with '&amp;' to ensure HTML validity
      if (!queryString) return '';
      return queryString.replace(/^&/, '?').replace(/&/g, '&amp;');
    },  
    isValidColor(color) {
      // Simple validation for hex codes and basic color names
      const s = new Option().style;
      s.color = color;
      // Return true if the browser accepts it as a valid color
      return s.color !== '';
    },
    isValidUrl(url) {
      try {
        const parsedUrl = new URL(url);
        return ['http:', 'https:'].includes(parsedUrl.protocol);
      } catch (error) {
        return false;
      }
    },
    formatImage(imgUrl, size, linkUrl = imgUrl) {
      const imageSizeStyle = size === 'full' ? 'max-width: 100%; height: auto;' : `max-width: ${size || 200}px; max-height: ${size || 200}px;`;
      const imageHtml = `<img src="${imgUrl}" style="${imageSizeStyle}">`;

      return `<a href="${linkUrl}" target="_blank" rel="noopener noreferrer">${imageHtml}</a>`;
    },
  toggleVideoSize(event) {
    console.log('toggleVideoSize.offsetWidth: ', event.target.offsetWidth);
    const videoContainer = event.target.parentElement;
    const iframe = videoContainer.querySelector('iframe');
    
    iframe.classList.toggle('video-full');
    iframe.classList.toggle('video-small');

    const button = event.target;

    // Adjust the height to maintain a 16:9 ratio if the iframe is now full size
    if (iframe.classList.contains('video-full')) {
      button.textContent = 'Collapse';
      const currentWidth = iframe.clientWidth;
      let setHeight = Math.round(currentWidth * 0.5625);
      iframe.height = Math.round(currentWidth * 0.5625); // 16:9 aspect ratio
      console.log('setHeight: ', setHeight)
      console.log('iframe.height: ', iframe.height);
    } else {
      button.textContent = 'Expand';
      // Set back to small size height
      iframe.height = 225; // Small size height (matching 400px width)
    }

  },
  },
  computed: {
        messageParsed() {
      let content = this.escapeHTML(this.$props.message.message);
      // Handle [color] tag
      content = content.replace(/\[color=(#[0-9a-f]{6}|#[0-9a-f]{3}|[a-zA-Z]+)\](.*?)\[\/color\]/gi, (match, color, text) => {
        // Ensure color is a valid CSS color
        if (this.isValidColor(color)) {
          return `<span style="color: ${color};">${text}</span>`;
        }
        return text;  // If not a valid color, just return the text
      });

      // Other replacements like [b], [i] can be added similarly
      content = content.replace(/\[b\](.*?)\[\/b\]/g, '<b>$1</b>');
      content = content.replace(/\[i\](.*?)\[\/i\]/g, '<i>$1</i>');
      content = content.replace(/\[u\](.*?)\[\/u\]/g, '<u>$1</u>');
      content = content.replace(/\[s\](.*?)\[\/u\]/g, '<s>$1</s>');

      // First, handle the case where an img tag is nested inside a link tag
      content = content.replace(/\[link=([^\]]+)\]\[img(?: size=(\d+|full))?\](https?:\/\/[^\s\[\]]+)\[\/img\]\[\/link\]/gi, (match, linkUrl, size, imgUrl) => {
        return this.formatImage(imgUrl, size, linkUrl);
      });

    // YouTube embedding with expandable size
    content = content.replace(/\[yt\]https?:\/\/(?:www\.youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)([\?&][^\[\]]*)?\[\/yt\]/gi, (match, videoId, queryString) => {
      const embedUrl = `https://www.youtube.com/embed/${videoId}${this.formatQueryString(queryString)}`;
      return `<div class="video-container">
                <iframe class="video-small" src="${embedUrl}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <button @click="toggleVideoSize" class="expand-button">Expand</button>
              </div>`;
    });
      
      // Handle [link] tag
      content = content.replace(/\[link=(https?:\/\/[^\s\[\]]+)\](.*?)\[\/link\]/gi, (match, url, text) => {
        if (this.isValidUrl(url)) {
          return `<a href="${url}" target="_blank" rel="noopener noreferrer">${text}</a>`;
        }
        return text;  // If URL is not valid, return just the text without a link
      });

      // Next, handle standalone img tags
      content = content.replace(/\[img(?: size=(\d+|full))?\](https?:\/\/[^\s\[\]]+)\[\/img\]/gi, (match, size, imgUrl) => {
        return this.formatImage(imgUrl, size);
      });
      
      // Parse [code] tags
      content = content.replace(/\[code\](.*?)\[\/code\]/gis, '<pre><code>$1</code></pre>');

      // Parse [size] tags with specified pixel size
      content = content.replace(/\[size=(\d+)\](.*?)\[\/size\]/gi, '<span style="font-size: $1px;">$2</span>');

      // Parse [spoiler] tags
      content = content.replace(/\[spoiler\](.*?)\[\/spoiler\]/gi, '<span class="spoiler" onclick="this.classList.toggle(\'revealed\');">$1</span>');

      return content;
    }
  }
}
</script>


<style>
.preview img {
  display: inline-block;
  max-width: 100%; /* Ensures images are responsive and fit within their container */
  max-height: 200px; /* Default maximum size, adjust as necessary */
  height: auto; /* Maintains aspect ratio */
  vertical-align: bottom; 
}

.video-container {
  margin-top: 5px;
  position: relative;
  width: 100%;
}

.video-small {
  width: 400px; /* Small size */
  height: 225px; /* Maintain 16:9 aspect ratio */
}

.video-full {
  width: 100%; /* Full container width */
  /*height: auto; /* Adjust height based on the new width to maintain aspect ratio */
}

.expand-button {
  position: absolute;
  top: 5px;
  left: 2px;
  padding: 5px 10px;
  font-size: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  cursor: pointer;
}

.expand-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
</style>