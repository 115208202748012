<template>
    <Form v-if="!this.$store.state.isLoggedIn" title="User Login" 
      :error="error"
      :fields="[{ id: 'username', label: 'Username' }, {id: 'password', label: 'Password', type: 'password' }]"
      @submit="login"
      submitText="Login" />
    <div v-else class="notice cel" >You are logged in as <span class="font-bold">{{this.$store.state.user.userName}}</span></div>
</template>

<script>

import { ref } from 'vue';

import ControlBar from '@/components/ControlBar.vue'
import Form from '@/components/Form.vue'
import PageBar from '@/components/PageBar.vue'

export default {
  name: 'Post',
  components: {
    ControlBar,
    Form,
    PageBar
  },
  props: {
    boardId: Number
  },
  async mounted(){
    this.initialize();
  },
  methods:{
    initialize(){
      // Set params from route
      if (this.$route.params.boards) {
        this.boards = this.$route.params.boards;
      }
      if (this.$route.params.boardCategories) {
        this.boardCategories = this.$route.params.boardCategories;
      }

      // Set global store information
      this.$store.state.screenTitle = 'Login'
      this.$store.state.screenType = 'login'
      this.$store.state.boardId = null
      this.$store.state.boardTitle = null
      this.$store.state.topicId = null
    },
    async login(data){
      await this.loginRequest(data.username, data.password);
      this.$emit('udpateControlBar');
    },
    loginRequest(username, password){
      let url = `${this.host}users/login.php`
      console.log('loginRequest: ', url)
      let data = 
      {
        username: username,
        password: password
      }
      let request = {
        method: 'POST',
        credentials: 'include', // This is crucial for cookies to be sent and received
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }

      // Sending the request to the server
      fetch(url, request)
        .then(response => response.json()) // Parsing the JSON response
        .then(data => {
            if (data.success) {
              
                let userInfo = {}
                try{
                  userInfo = JSON.parse(data.userInfo)
                }catch(e){}
                
                // Set logged in state in store
                this.$store.state.user = userInfo;
                this.$store.state.isLoggedIn = true;
                this.$router.push('/');
            } else {
                console.log('Login failed:', data.message);
                this.error = data.message
                this.$store.state.user = {};
                this.$store.state.isLoggedIn = false;
            }
        })
        .catch(error => {
            console.error('Error login:', error);
            this.error = error
            this.$store.state.user = {};
            this.$store.state.isLoggedIn = false;
        });
    }
  },
  setup(){
    const error = ref('')
  return {
      error
    }
  }
}
</script>
