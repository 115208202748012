<template>
  <div>
    User Logout
  </div>
</template>

<script>

import { ref } from 'vue';

export default {
  name: 'Logout',
  components: {
  },
  props: {
  },
  data(){
    return {
      topicInfo: []
    }
  },
  async mounted(){
    document.title = 'Logout';
    await this.logout();
    this.$router.push('/');
  },
  methods:{
    logout(){
        this.$store.state.isLoggedIn = false;
        this.$store.state.user = {};
        
        let url = `${this.host}users/logout.php`;
        let request = {
          method: 'GET',
          credentials: 'include'
        }
        fetch(url, request)
        .then(response => response.json()) 
        .then(data => {
          //console.log('logoutSuccess', data);
        })
        .catch(error => {
          console.log('logoutError: ', error);
        });
    },
  },
  setup(){    
    return {
    }
  }
}
</script>
