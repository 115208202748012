<template>
  <PageBar :pages="pages" :page="page" />
  <!-- Topic List Header -->
  <div class="board-header">
    <div class="board-title cel">Topic</div>
    <div class="board-user cel">Created By</div>
    <div class="board-messages cel">Msgs</div>
    <div class="board-last-post cel">Last Post</div>
  </div>
  <!-- Topics -->
  <div v-for="topic in topics" :key="topic.topicId"
    class="board-topic">
    <div class="board-title cel"><a :href="`/b${this.boardId}/t${topic.topicId}`">{{topic.title}}</a></div>
    <div class="board-user cel"><a :href="`/b${this.boardId}/u${topic.userName}`">{{topic.userName}}</a></div>
    <div class="board-messages cel">{{topic.messageCount}}</div>
    <div class="board-last-post cel">{{formatDateTime(topic.lastPost)}}</div>
  </div>
  <PageBar :pages="pages" :page="page" :isNumbered="true" />
</template>

<script>

import { ref } from 'vue';

import ControlBar from '@/components/ControlBar.vue'
import PageBar from '@/components/PageBar.vue'

export default {
  name: 'GenTopic',
  components: {
    ControlBar,
    PageBar
  },
  props: {
    boardId: Number
  },
  data(){
    return {
      topics: [],
      boardInfo: []
    }
  },
  async mounted(){
    this.initialize();
  },
  methods:{
    initialize(){
      
      // Set params from route
      if (this.$route.params.topics) {
        this.topics = this.$route.params.topics;
      }
      if (this.$route.params.boardInfo) {
        this.boardInfo = this.$route.params.boardInfo;
      }
      
      this.$store.state.dynamicUrl = `/b${this.$store.state.boardId}`;

      // Set screen title and type
      this.$store.state.screenTitle = this.$store.state.boardTitle
      document.title = this.$store.state.screenTitle

      this.$store.state.screenType = 'board'
    },
    formatDateTime(dateStr) {
      const date = new Date(dateStr);
      const month = date.getMonth() + 1; 
      const day = date.getDate();
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      hours = hours % 12;
      hours = hours ? hours : 12; 
      const minutesPadded = minutes < 10 ? '0' + minutes : minutes;

      return `${month}/${day} ${hours}:${minutesPadded}${ampm}`;
    }
  },
  setup(){

    let page = ref(1);
    let pages = ref(1);
    let topics = ref([]);

    return {
      page,
      pages,
      topics

    }
  }
}
</script>
