<template>
  <div id="sidebar" :class="`sidebar fixed`">
    <span class="sidebar-header">PROJECT B8</span>
    <a v-for="option in [
      { label: 'Home', to: '/' },
      { label: 'Boards', to: '/b' },
      { label: 'Board8', to: '/b8' },
      { label: 'Feature Requests, Bug Reports', to: '/b2' }
    ]" :key="option.to"
      class="sidebar-option" :href="option.to" @Xclick="optionSelected(option)">
      {{option.label}}
    </a>
  </div>
  <!--Take up same width as sidebar-->
  <div class="sidebar h-2 flex-shrink-0" />
</template>

<script>
export default {
  name: 'Sidebar',
  props: {
    msg: String
  },
  methods: {
    async optionSelected(option){
      await this.$router.push(option.to)
    }
  }
}
</script>
