<template>
  <div class="topbar">
    <!--Path-->
    <div class="topbar-path cel">
      <a href="/" class="font-bold">ProjectB8</a>      
      <a v-if="this.$store.state.screenType != 'home'" href="/b">Boards</a> 
      <!-- Board -->         
      <a v-if="this.$store.state.boardId" :href="`/b${this.$store.state.boardId}`">{{this.$store.state.boardTitle}}</a>
      <!-- Topic -->      
      <a v-if="this.$store.state.topicId" :href="`/b${this.$store.state.boardId}/t${this.$store.state.topicId}`">{{this.$store.state.topicTitle}}</a>
    </div>
    <!--User Controls-->
    <div class="topbar-user-controls cel">
      <a v-for="(option, i) in userOptions" :key="option.emit" :href="option.to" @Xclick="this.$emit(option.emit)">{{option.label}}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Path',
  props: {
    msg: String
  },
  computed: {
    
    userOptions(){
      let options = [];
      
      // User, login, and register
      if(this.$store.state.isLoggedIn){
        options.push({ label: `${this.$store.state.user.userName} (${this.$store.state.user.userLevel})`, to: `${this.$store.state.dynamicUrl}/u`, emit: 'profile' });
        options.push({ label: `Messages${this.$store.state.unreadMessagesCount > 0 ? ' ('+this.$store.state.unreadMessagesCount+')' : ''}`, to: `${this.$store.state.dynamicUrl}/pms` });
        //options.push({ label: `Notifications`, emit: 'notifications' });
        options.push({ label: `Logout`, to: '/logout', emit: 'logout' });
      }else{
        options.push({ label: `Login`, to: '/login', emit: 'login' });
        options.push({ label: `Register`, to: '/register', emit: 'register' });
      }

      return options;
    }

  },
  methods: {
    optionSelected(option){
      this.$router.push(option.to)
    }
  }
}
</script>
