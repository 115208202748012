import { createStore } from 'vuex'

export default createStore({
  state: {
    boardId: '',
    boardTitle: '',
    dynamicUrl: '',
    loaded: false,
    messagesPerPage: 25,
    screenTitle: '',
    screenType: '',
    showSidebar: true,
    stickyHeader: true,
    topicId: '',
    topicTitle: '',
    topicsPerPage: 20,
    user: {},
    isLoggedIn: false
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
