<template>
    <!-- Board List Header -->
    <div class="boardlist-header">
      <div class="boardlist-title cel">Board Title</div>
      <div class="boardlist-topics cel">Topics</div>
      <div class="boardlist-messages cel">Msgs</div>
      <div class="boardlist-last-post cel">Last Post</div>
    </div>
    <!-- Uncategorized Boards -->
    <div v-for="board in uncategorizedBoards" :key="board.boardId" class="boardlist-board">
      <div class="boardlist-title cel"><a :href="`/b${board.boardId}`">{{board.title}}</a></div>
      <div class="boardlist-topics cel">{{board.topicCount}}</div>
      <div class="boardlist-messages cel">{{board.messageCount}}</div>
      <div class="boardlist-last-post cel">{{board.lastPost}}</div>
    </div>
    <!-- Categorized Boards -->
    <div v-for="category in categoriesToShow" :key="category.categoryId" class="boardlist-group">
      <div class="boardlist-category cel">{{category.title}}</div>
      <!-- Category Boards -->
      <div v-for="board in categoryBoards(category.categoryId)" :key="board.boardId" class="boardlist-board">
        <div class="boardlist-title cel"><a :href="`/b${board.boardId}`">{{board.title}}</a></div>
        <div class="boardlist-topics cel">{{board.topicCount}}</div>
        <div class="boardlist-messages cel">{{board.messageCount}}</div>
        <div class="boardlist-last-post cel">{{board.lastPost}}</div>
      </div>
    </div>
    <!-- Recently Active Topics -->
    <div class="boardlist-category cel">Recently Active Topics</div>
      <div v-for="topic in recentTopics" :key="topic.topicId" class="boardlist-board">
        <div class="boardlist-title cel">
          <a :href="`/b${topic.boardId}/t${topic.topicId}`">{{topic.title}}</a>,
          <a :href="`/b${topic.boardId}`" class="boardlist-small">{{topic.boardTitle}}</a>
        </div>
        <div class="boardlist-topics cel">
            <a :href="`/u${topic.lastPosterName}`">{{topic.lastPosterName}}</a>
          </div> 
        <div class="boardlist-messages cel">{{topic.messageCount}}</div>
        <div class="boardlist-last-post cel">{{topic.lastPostTime}}</div>       
    </div>
</template>

<script>

import { ref } from 'vue';

import ControlBar from '@/components/ControlBar.vue'
import PageBar from '@/components/PageBar.vue'

export default {
  name: 'BoardList',
  components: {
    ControlBar,
    PageBar
  },
  data(){
    return {
      boards: [],
      boardCategories: [],
      recentTopics: []
    }
  },
  async mounted(){
    this.initialize()
  },
  computed: {
    categoriesToShow(){
      let cats = [];
      for(var ii = 0; ii < this.boardCategories.length; ii++){
        if(true || this.hasAnyBoards(this.boardCategories[ii].categoryId))
          cats.push(this.boardCategories[ii])
      }
      return cats;
    },
    uncategorizedBoards(){
      let boards = [];
      for(var ii = 0; ii < this.boards.length; ii++){
        if(this.boards[ii].categoryId == 0)
          boards.push(this.boards[ii]);
      }
      return boards;
    }
  },
  methods:{
    initialize(){
      // Set params from route
      if (this.$route.params.boards) {
        this.boards = this.$route.params.boards;
      }
      if (this.$route.params.boardCategories) {
        this.boardCategories = this.$route.params.boardCategories;
      }
      if (this.$route.params.recentTopics) {
        this.recentTopics = this.$route.params.recentTopics;
      }

      this.$store.state.dynamicUrl = `/b`;

      // Set global store information
      this.$store.state.screenTitle = 'Board List'
      this.$store.state.screenType = 'boardList'
      this.$store.state.boardId = null
      this.$store.state.boardTitle = null
      this.$store.state.topicId = null
      document.title = this.$store.state.screenTitle
    },
    categoryBoards(categoryId){
      let boards = [];
      for(var ii = 0; ii < this.boards.length; ii++){
        if(this.boards[ii].categoryId == categoryId)
          boards.push(this.boards[ii]);
      }
      return boards;
    },
    hasAnyBoards(categoryId){
      for(var ii = 0; ii < this.boards.length; ii++){
        if(this.boards[ii].categoryId == categoryId)
          return true;
      }
      return false;
    },
    selectBoard(boardId){
      this.$router.push(`/b${boardId}`);
    }
  },
  setup(){    
    return {
    }
  }
}
</script>
